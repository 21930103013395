import React, { useEffect, useState } from "react";
import { Card, Tab, Nav, Dropdown } from "react-bootstrap";
import VideoCard from "../components/VideoCard";
import PageTitle from "../layouts/PageTitle";
import {
  actionPosts,
  getAllPosts,
  markWinner,
} from "../../services/Videos/VideoService";
// import AddPost from "../modal/AddPost";
import menu from "../../images/svg/menu copy.svg";
import ViewVideosInfo from "../modal/ViewVideosInfo";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
import AssignTags from "../modal/AssignTags";

export default function VideoManagement() {
  const [selectedTab, setSelectedTab] = useState("pending");
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [viewTags, setViewTags] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState({});
  const [videoData, setVideoData] = useState({});
  const [singleId, setSingleId] = useState("");
  const [prevTags, setPrevTags] = useState([]);
  const limit = 12;

  const handleCheckboxChange = (id) => {
    setCheckBoxValue((prevCheckBoxValue) => ({
      ...prevCheckBoxValue,
      [id]: !prevCheckBoxValue[id],
    }));
  };

  // Construct the response object
  const ids = {
    postId: Object.keys(checkBoxValue).filter((id) => checkBoxValue[id]),
  };
  console.log(ids?.postId, "ids");
  const tabData = [
    {
      name: "Pending Approval",
      icon: "pending",
    },
    {
      name: "Active",
      icon: "approved",
    },
    {
      name: "Inactive",
      icon: "inactive",
    },

    {
      name: "Deleted",
      icon: "deleted",
    },
    {
      name: "Rejected",
      icon: "rejected",
    },
  ];

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllPosts(
        currentPage,
        limit,
        selectedTab,
        "false"
      );
      console.log(response.data, "posts");
      setUsers(response?.data?.result?.docs);
      const total = response.data.result?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.result?.total);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  const onAction = async (status, winner) => {
    // console.log(status, winner, "status,winner");
    // setLoader(true);
    try {
      const response = await actionPosts(status, winner, ids);
      notifyTopRight(response.data?.responseMessage);
      getTableData();
      console.log(response);
      setCheckBoxValue({});
    } catch (error) {
      console.log(error.response, "helooooooooo");
      notifyError(error.response.data?.responseMessage);
    } finally {
      // setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, selectedTab]);

  return (
    <div>
      <PageTitle activeMenu="Videos List" motherMenu="All Video" />
      <div className="col-xl-12 pr-0">
        <Card className="post-card">
          <Card.Header className="d-block">
            {ids?.postId?.length > 0 && (
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => onAction("approved", "true")}
                >
                  Approve and Promote to Winners
                </button>
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => onAction("approved", "false")}
                >
                  Approve
                </button>
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => onAction("rejected", "false")}
                >
                  Reject
                </button>
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() => setViewTags(true)}
                >
                  Assign Tags
                </button>
              </div>
            )}
          </Card.Header>
          <Card.Body>
            <div className="custom-tab-1">
              <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <Nav as="ul" className="nav-tabs">
                  {tabData?.map(
                    (data, i) =>
                      i !== tabData.length && (
                        <Nav.Item
                          as="li"
                          key={i}
                          onClick={() => setSelectedTab(data?.icon)}
                        >
                          <Nav.Link
                            eventKey={data?.name.toLowerCase()}
                            className="d-flex align-items-center tab-section"
                          >
                            <li className="m-0 pr-3">{data?.name}</li>
                          </Nav.Link>
                        </Nav.Item>
                      )
                  )}
                </Nav>
                {loader ? (
                  <Spinner />
                ) : (
                  <Tab.Content className="pt-4 pb-4">
                    {selectedTab === "approved" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {users &&
                          users?.map((item, i) => (
                            <VideoCard
                              options={[
                                "View",
                                "Promote to Winner",
                                "Inactive",
                                "Delete",
                              ]}
                              id={item?._id}
                              data={item}
                              setViewModal={setViewModal}
                              setVideoData={setVideoData}
                              apifunc={actionPosts}
                              table={getTableData}
                              winner={"false"}
                              setSingleId={setSingleId}
                              singleId={singleId}
                            />
                          ))}
                      </div>
                    )}
                    {selectedTab === "inactive" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {users?.map((item, i) => (
                          <VideoCard
                            options={[
                              "View",
                              "Active",
                              "Active and Promote to winner",
                            ]}
                            id={item?._id}
                            data={item}
                            setViewModal={setViewModal}
                            setVideoData={setVideoData}
                            apifunc={actionPosts}
                            table={getTableData}
                            winner={"false"}
                          />
                        ))}
                      </div>
                    )}
                    {selectedTab === "deleted" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {users?.map((item, i) => (
                          <VideoCard
                            options={["View"]}
                            setViewModal={setViewModal}
                            setVideoData={setVideoData}
                            id={item?._id}
                            data={item}
                          />
                        ))}
                      </div>
                    )}
                    {selectedTab === "pending" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {users?.map((item, i) => (
                          <VideoCard
                            options={[
                              "View",
                              "Approve and Promote to winner",
                              "Approve",
                              "Reject",
                              "Assign Tags",
                            ]}
                            multi={"multi"}
                            id={item?._id}
                            data={item}
                            handleCheckboxChange={handleCheckboxChange}
                            setViewModal={setViewModal}
                            setVideoData={setVideoData}
                            apifunc={actionPosts}
                            table={getTableData}
                            winner={"false"}
                            setViewTags={setViewTags}
                            setSingleId={setSingleId}
                            singleId={singleId}
                            tags={setPrevTags}
                          />
                        ))}
                      </div>
                    )}
                    {selectedTab === "rejected" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {users?.map((item, i) => (
                          <VideoCard
                            options={[
                              "View",
                              "Approve and Promote to winner",
                              "Approve",
                              "Delete",
                            ]}
                            id={item?._id}
                            data={item}
                            setViewModal={setViewModal}
                            setVideoData={setVideoData}
                            apifunc={actionPosts}
                            table={getTableData}
                            winner={"false"}
                          />
                        ))}
                      </div>
                    )}
                    {users?.length === 0 && !loader ? (
                      <div className="justify-content-center d-flex my-5 ">
                        Sorry, Data Not Found!
                      </div>
                    ) : (
                      ""
                    )}
                  </Tab.Content>
                )}
              </Tab.Container>
              {users?.length !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-block d-sm-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {currentPage * limit + 1} to{" "}
                      {listLength > (currentPage + 1) * limit
                        ? (currentPage + 1) * limit
                        : listLength}{" "}
                      of {listLength} entries
                    </div>
                    <Pagination
                      pageCount={pageCount}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      {viewModal && (
        <ViewVideosInfo
          show={viewModal}
          data={videoData}
          onHide={() => setViewModal(false)}
        />
      )}
      {viewTags && (
        <AssignTags
          show={viewTags}
          table={getTableData}
          postIds={ids}
          singleId={singleId}
          prevTags={prevTags}
          setCheckBoxValue={setCheckBoxValue}
          onHide={() => setViewTags(false)}
        />
      )}
    </div>
  );
}
