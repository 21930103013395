import React from "react";
import "../css/VideoCardCSS.css";
import menu from "../../images/svg/menu.svg";
import { Dropdown } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";

const VideoCard = ({
  options,
  multi,
  id,
  data,
  handleCheckboxChange,
  setViewModal,
  setVideoData,
  apifunc,
  winner,
  table,
  setViewTags,
  setSingleId,
  singleId,
  tags,
}) => {

  const onAction = async (status, winner) => {
    let postId = { postId: [id] };
    try {
      const response = await apifunc(status, winner, postId);
      notifyTopRight(response.data?.responseMessage);
      table();
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      notifyError(error.response.data?.message);
    }
  };

  // Check if 'Assign Tags' option is selected and data.tags length is 0
  const disableApprovalOptions =
    options.includes("Assign Tags") && data?.tags?.length === 0;

  return (
    <div className="reel-card">
      <img className="video-player" src={data?.fileThumbnail} alt="" />
      <div className="option-icon">
        {multi ? (
          <input type="checkbox" onChange={() => handleCheckboxChange(id)} />
        ) : (
          <div></div>
        )}
        <Dropdown>
          <Dropdown.Toggle
            variant="transparent light"
            className="light sharp btn i-false p-0 text-right"
          >
            <img src={menu} alt="Menu" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0">
            {options &&
              options?.map((option, i) => (
                <Dropdown.Item
                  key={i}
                  className="text-wrap"
                  onClick={() => {
                    if (option === "View") {
                      setViewModal(true);
                      setVideoData(data);
                    } else if (option === "Approve") {
                      onAction("approved", winner);
                    } else if (option === "Reject") {
                      onAction("rejected", winner);
                    } else if (option === "Inactive") {
                      onAction("inactive", winner);
                    } else if (option === "Active and Promote to winner") {
                      onAction("approved", "true");
                    } else if (option === "Promote to Winner") {
                      onAction("approved", "true");
                    } else if (option === "Delete") {
                      onAction("deleted", winner);
                    } else if (option === "Approve and Promote to winner") {
                      onAction("approved", "true");
                    } else if (option === "Active") {
                      onAction("approved", winner);
                    } else if (option === "Assign Tags") {
                      tags(data?.tags);
                      setSingleId(id);
                      setViewTags(true);
                    }
                  }}
                  disabled={
                    disableApprovalOptions &&
                    (option === "Approve" ||
                      option === "Approve and Promote to winner")
                  }
                >
                  {option}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="user-info">
        <span className="username">{data?.userId?.name}</span>
      </div>
    </div>
  );
};

export default VideoCard;
