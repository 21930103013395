import React, { useState } from "react";

import { Modal } from "react-bootstrap";

import { notifyError, notifyTopRight } from "../common/Toaster";
import { addBanner } from "../../services/Videos/VideoService";
import { imageUpload } from "../../services/AuthService";
export default function AddBanner({ show, table, onHide }) {
  //Modal box
  let errorsObj = { name: "", file: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [file, setFile] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    file: "",
    fileName: "",
    fileType: "",
  });

  const handleInputChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile, "image before upload");
    if (selectedFile) {
      imageUpload(selectedFile)
        .then((response) => {
          console.log(response, "image loaction");
          setFormData({
            ...formData,
            file: response.data?.result?.url,
            fileName: response.data?.result?.fileName,
            fileType: response.data?.result?.type,
          });
          setErrors({...errors,file:""})
        })
        .catch((error) => {
          console.log(error, "image upload error");
        });
    }
  };
  async function onSubmitBanner(e) {
    console.log("ffffffff");
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData.name.trim() === "") {
      errorObj.name = "This field is required";
      error = true;
    }
    if (formData.file === "") {
      errorObj.file = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await addBanner(formData);
      table();
      onHide();
      notifyTopRight("Created Successfully!");
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      // setLoader(false);
    }
  }
  return (
    <>
      <Modal className="modal fade" show={show} onHide={onHide} centered>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSubmitBanner}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Banner</h4>
                {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
              </div>
              <div className="modal-body">
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          value={formData.name}
                          onChange={(e) =>{
                            setFormData({ ...formData, name: e.target.value });
                            setErrors({...errors,name:""})
                          }}
                          // placeholder="Enter your old password"
                        />
                        <span className="validation-text"></span>
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">File</label>
                      <div className="contact-name">
                        <input
                          type="file"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          onChange={handleInputChange}
                          placeholder="Enter new password"
                        />
                        <span className="validation-text"></span>
                        {errors.file && (
                          <div className="text-danger fs-12">{errors.file}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  // onClick={onChangePassword}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
