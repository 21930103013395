import axiosInstance from "../AxiosInstance";
import {
  ADD_BANNER,
  ASSIGN_TAGS,
  DELETE_REASON,
  DELETE_TAGS,
  GET_BANNERS,
  GET_POSTS,
  GET_REASONS,
  GET_REPORTS,
  GET_TAGS,
  MARK_POSTS,
  POST_REASON,
  POST_TAGS,
  PUT_BANNER,
  PUT_POSTS,
  PUT_REASON,
  UPLOAD_WINNER_VIDEO,
} from "./VideosEndPoints";

export function getAllPosts(currentPage, limit, status, isWinner) {
  return axiosInstance.get(
    GET_POSTS +
      `?page=${currentPage}&limit=${limit}&status=${status}&isWinner=${isWinner}`
  );
}
export function actionPosts(status, winner, id) {
  // const postData= {postId:id}
  return axiosInstance.put(
    PUT_POSTS + `?status=${status}&winner=${winner}`,
    id
  );
}
export function markWinner(id) {
  // const postData= {postId:id}
  return axiosInstance.post(MARK_POSTS, id);
}
///////////////////////////////===================banner====================//////////////////////////
export function getBannerList(currentPage, limit, search) {
  const searchQuery = search ? `&search=${search}` : ""; // Conditionally add search query

  return axiosInstance.get(
    GET_BANNERS + `?page=${currentPage}&limit=${limit}${searchQuery}`
  );
}

export function addBanner(formData) {
  return axiosInstance.post(ADD_BANNER, formData);
}

export function uploadWinnerVideo(formData) {
  return axiosInstance.post(UPLOAD_WINNER_VIDEO, formData);
}

export function updateBanner(id, status) {
  const postData = { active: status };
  return axiosInstance.put(PUT_BANNER + `?_id=${id}`, postData);
}

//////////////////===================  flag content================/////////////////////////////////

export function getReportsList(currentPage, limit, search) {
  const searchQuery = search ? `&search=${search}` : ""; // Conditionally add search query

  return axiosInstance.get(
    GET_REPORTS + `?page=${currentPage}&limit=${limit}${searchQuery}`
  );
}

///////////////////////////=================== reason====================///////////////////////////////////

export function getReasonList(currentPage, limit, search) {
  const searchQuery = search ? `&search=${search}` : ""; // Conditionally add search query

  return axiosInstance.get(
    GET_REASONS + `?page=${currentPage}&limit=${limit}${searchQuery}`
  );
}

export function createReason(formData) {
  return axiosInstance.post(POST_REASON, formData);
}

export function updateReason(id, formData) {
  return axiosInstance.put(PUT_REASON + `?_id=${id}`, formData);
}
export function deleteReason(id) {
  return axiosInstance.delete(DELETE_REASON + `?_id=${id}`);
}

///////////////////////////=================== hashtags ====================///////////////////////////////////

export function getTagsList(currentPage, limit, search) {
  const searchQuery = search ? `&search=${search}` : ""; // Conditionally add search query

  return axiosInstance.get(
    GET_TAGS + `?page=${currentPage}&limit=${limit}${searchQuery}`
  );
}

export function createTags(formData) {
  return axiosInstance.post(POST_TAGS, formData);
}

export function deleteTags(id) {
  return axiosInstance.delete(DELETE_TAGS + `?_id=${id}`);
}

export function assignTags(payload) {
  // const postData = {
  //   postIds,
  //   tags,
  // };
  return axiosInstance.post(ASSIGN_TAGS, payload);
}
