import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { createReason } from "../../services/Videos/VideoService";


export default function AddReason({ show, table, onHide }) {
  //Modal box
  let errorsObj = { name: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [formData, setFormData] = useState({
    name: "",
  });

  async function onSubmitBanner(e) {
    console.log("ffffffff");
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData.name.trimEnd() === "") {
      errorObj.name = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await createReason(formData);
      table();
      onHide();
      notifyTopRight("Created Successfully!");
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      // setLoader(false);
    }
  }
  return (
    <>
      <Modal className="modal fade" show={show} onHide={onHide} centered>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSubmitBanner}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Reason</h4>
                {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
              </div>
              <div className="modal-body">
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          value={formData.name}
                          onChange={(e) =>{
                            setFormData({ ...formData, name: e.target.value });
                            setErrors({...errors,name:""});
                          }}
                          // placeholder="Enter your old password"
                        />
                        <span className="validation-text"></span>
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
