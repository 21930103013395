import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { assignTags, getTagsList } from "../../services/Videos/VideoService";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function AssignTags({
  show,
  table,
  postIds,
  singleId,
  prevTags,
  setCheckBoxValue,
  onHide,
}) {
  const [users, setUsers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // console.log(prevTags,"prevTags")
  useEffect(() => {
    const getTableData = async () => {
      try {
        const response = await getTagsList(0, 0, "");
        setUsers(response?.data?.result?.docs);
      } catch (error) {
        console.log(error, "error");
      }
    };
    getTableData();
    setSelectedIds(prevTags ? prevTags : []);
  }, []);

  const handleSelectedId = (name) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(name)
        ? prevSelectedIds.filter((id) => id !== name)
        : [...prevSelectedIds, name]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = users.map((user) => user.name);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  const onSaveTags = async () => {
    const payload = {
      postIds: singleId ? [singleId] : postIds.postId,
      tags: selectedIds,
    };

    try {
      await assignTags(payload);
      onHide();
      notifyTopRight("Assigned Successfully!");
      table();
      setCheckBoxValue({});
    } catch (error) {
      notifyError(error?.response?.data?.responseMessage);
      console.log(error, "error");
    }
  };

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <Table responsive>
        <thead className="text-black">
          <tr>
            <th className="" style={{ maxWidth: "50px" }}>
              <div className="custom-control custom-checkbox checkbox-success check-lg">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="checkbox1_exam_all"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                <label
                  className="custom-control-label"
                  htmlFor="checkbox1_exam_all"
                ></label>
              </div>
            </th>
            <th>
              <strong>Name</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.map((item, i) => (
            <tr key={i}>
              <td style={{ maxWidth: "50px" }}>
                <div className="custom-control custom-checkbox checkbox-success check-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={item._id}
                    checked={selectedIds.includes(item.name)}
                    onChange={() => handleSelectedId(item.name)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={item._id}
                  ></label>
                </div>
              </td>
              <td>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {users?.length === 0 ? (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      ) : null}

      <div className="modal-footer">
        <button
          type="button"
          disabled={selectedIds.length === 0}
          className="btn btn-primary"
          onClick={onSaveTags}
        >
          SAVE
        </button>
        <button type="button" onClick={onHide} className="btn btn-secondary">
          <i className="flaticon-delete-1"></i> Cancel
        </button>
      </div>
    </Modal>
  );
}
