import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { uploadWinnerVideo } from "../../services/Videos/VideoService";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function UploadWinnerVideo({ show, onHide,handlerChange }) {
  const [loading,setLoading]=useState(false)
  const [videoFile, setVideoFile] = useState(null);
  const [error, setError] = useState(null);

  async function onSubmitBanner(e) {
    setLoading(true)
    e.preventDefault();
    const formVideoData = new FormData();
    formVideoData.append("file", videoFile);
    try {
      const response = await uploadWinnerVideo(formVideoData);
      onHide();
      handlerChange()
      notifyTopRight("Added Successfully!");
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      setLoading(false)

    }
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 200 * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      setError("File size exceeds 200MB. Please upload a smaller file.");
    } else {
      setError("");
      setVideoFile(file);
    }
  };

  return (
    <>
      <Modal className="modal fade" show={show} onHide={onHide} centered>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSubmitBanner}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Upload Video</h4>
              </div>
              <div className="modal-body">
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">File</label>
                      <div className="contact-name">
                        <input
                          type="file"
                          accept="video/*"
                          className="form-control"
                          autocomplete="off"
                          name="file"
                          onChange={handleFileInputChange}
                          placeholder="Upload file"
                        />
                        <span className="validation-text"></span>
                        {error && (
                          <div className="text-danger fs-12">{error}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {loading ? "Loading" : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
