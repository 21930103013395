/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      users = ["user-management"],
      web = ["website-management"],
      flag = ["flagged-content"],
      reason = ["flag-reasons-list"],
      noti = ["notifications"],
      hashtag = ["hashtags"],
      videos = ["video-management", "winner-video-management"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                <i className="flaticon-381-networking"></i>

                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link to="user-management" className="ai-icon">
                <i className="flaticon-381-user-9"></i>

                <span className="nav-text">User Management</span>
              </Link>
            </li>

            <li className={`${videos.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-video-player-1"></i>
                {/* <img src={articalIcon}></img> */}
                <span className="nav-text ">Videos</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "video-management" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/video-management"
                  >
                    All Videos
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "winner-video-management" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/winner-video-management"
                  >
                    Winner Videos
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${web.includes(path) ? "mm-active" : ""}`}>
              <Link to="website-management" className="ai-icon">
                <i className="flaticon-381-internet"></i>
                <span className="nav-text">Website Management</span>
              </Link>
            </li>
            <li className={`${flag.includes(path) ? "mm-active" : ""}`}>
              <Link to="flagged-content" className="ai-icon">
                <i className="flaticon-381-flag-4"></i>
                <span className="nav-text">Flagged Content</span>
              </Link>
            </li>
            <li className={`${reason.includes(path) ? "mm-active" : ""}`}>
              <Link to="flag-reasons-list" className="ai-icon">
                <i className="flaticon-381-list"></i>
                <span className="nav-text">Flag Reasons</span>
              </Link>
            </li>
            <li className={`${hashtag.includes(path) ? "mm-active" : ""}`}>
              <Link to="hashtags" className="ai-icon">
                <i className="flaticon-381-">#</i>
                <span className="nav-text">Hashtags</span>
              </Link>
            </li>
            <li className={`${noti.includes(path) ? "mm-active" : ""}`}>
              <Link to="notifications" className="ai-icon">
                <i className="flaticon-381-ring"></i>
                <span className="nav-text">Notifications</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
