import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { postNotification } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { Button, Card, Col, Nav, Tab } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const optionsArray = [
  { key: "allUsers", label: "All Users" },
  { key: "newUsers", label: "New Users" },
  { key: "inactive", label: "In Active" },
  { key: "noPosts", label: "No Posts" },
];

export default function Notifications(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(10);
  const [loader, setLoader] = useState(false);
  let errorsObj = { title: "", body: "", selectedMultiOptions:""};
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [requests, setRequests] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("daily");
  const [sort, setSort] = useState("desc");
  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title.trim() === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (body.trim() === "") {
      errorObj.body = "Body is Required";
      error = true;
    }
    if (selectedOptions.length === 0) {
      errorObj.selectedMultiOptions = "User type is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await postNotification(title, body ,selectedOptions,selectedOption);
      notifyTopRight(response.data?.responseMessage);
      setTitle("");
      setBody("");
      setSelectedOptions([])
      setSelectedOption(10)

      setLoader(false);
    } catch (error) {
      notifyError(error?.response?.data?.responseMessage);
      setLoader(false);
    }
  };

  const handleOnChange = (selected) => {
    setErrors({ ...errors, selectedMultiOptions: "" });
    setSelectedOptions(selected);
  };

  console.log(selectedOptions, "sdjhjshdfjksh");

  const handleOnChangeSingle = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    console.log("Selected:", value);
  };
  return (
    <div>
      <PageTitle activeMenu="Push Notification" motherMenu="Notifications" />

      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home">Push Notifications</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4"></Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="home">
              <div className="col-lg-8 col-md-7 ">
                <div className="authincation-content text-black p-5">
                  <div className="mb-4">
                    <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Title</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setErrors({ ...errors, title: "" });
                        }}
                        placeholder="Enter title"
                      />
                      {errors.title && (
                        <div className="text-danger fs-12">{errors.title}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Body</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={body}
                        onChange={(e) => {
                          setBody(e.target.value);
                          setErrors({ ...errors, body: "" });
                        }}
                        placeholder="Write something here.."
                      />
                      {errors.body && (
                        <div className="text-danger fs-12">{errors.body}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Select User Type</strong>
                      </label>
                      <div id="multiselect">
                        <DropdownMultiselect
                          options={optionsArray}
                          name="countries"
                          selected={selectedOptions}
                          handleOnChange={handleOnChange}
                          placeholder="Nothing selected"
                          buttonClass="btn-light"
                          optionKey="key"
                          optionLabel="label"
                          selectDeselectLabel="Select/Deselect All"
                        />
                        {errors.selectedMultiOptions && (
                        <div className="text-danger fs-12">{errors.selectedMultiOptions}</div>
                      )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Inactive Days</strong>
                      </label>
                      <select
                        value={selectedOption}
                        onChange={handleOnChangeSingle}
                        className="form-control"
                      >
                        <option value="option" disabled>
                          Select an option
                        </option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                      </select>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {loader ? "Loading" : "Send"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}
